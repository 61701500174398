.app-login-page .app-login-page__toolbar {
  position: relative;
  box-shadow: none !important;
  background-color: #EEEEEE;
}
.app-login-page .v-toolbar__title {
  min-width: max-content;
}
.app-login-page .app-login-page__toolbar-a {
  margin-left: 30px;
  margin-right: 40px;
  text-decoration: underline;
  text-underline-offset: 3px;
  text-decoration-thickness: 1px;
  -webkit-text-decoration-style: dashed;
  text-decoration-style: dashed;
}
.app-login-page .app-content {
  padding-top: 0 !important;
}
.v-dialog {
  border-radius: 16px;
}
.app-dialog-card {
  padding: 40px 32px;
  width: 350px;
  background: white;
  border-radius: 16px;
  overflow: hidden;
}
.app-dialog-card .app-dialog-card__title {
  margin-bottom: 10px;
}
.app-cities-list {
  padding: 0;
  height: 480px;
  overflow-y: auto;
  overflow-x: hidden;
}
.app-cities-list .v-list__tile {
  cursor: pointer;
}
.app-cities-list .v-list__tile:hover {
  color: #145ba3;
}
.app-current-city {
  color: #1976d2;
  font-weight: 600;
}
